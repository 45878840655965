/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'
// import _ from 'lodash'
// import { css, jsx } from '@emotion/core'

import {
  Page,
  Flex,
  Box,
  Image,
  Link,
  // H1,
  // Text,
  // FeatherIcon,
  Hr,
  // Circle,
  Truncate,
  Paper,
  H2,
} from '../elements'
import {
  BoardgamePreview,
  // TournamentPrizes,
  Description,
  PeoplePreview,
  AnimationEntry,
  Album,
  // More,
  FacebookCircle,
  HeadingComplex,
  SectionExhibitors,
  SectionAnimations,
  SectionBoardgames,
  Youtube,
  SEO,
  AnimationCategories,
  PlaceIcon,
} from '../components'

const GuestTemplate = ({ data: { guest } }) => {
  const {
    relativePath,
    nameCanonical,
    name,
    nameFallback,
    featuredImage,
    description,
    exhibitors = [],
    animations = [],
    authorOf = [],
    illustratorOf = [],
  } = guest

  const mainColor = 'guest'
  // const category = _.camelCase(categories[0])

  return (
    <Page>
      {({ t, currentLocale }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `${relativePath}`,
              title: nameFallback[currentLocale],
              // description: true,
            }}
          />
          <HeadingComplex
            {...{
              type: 'guest',
              // icon: category,
              // iconColor: `flag_${category}`,
              nameCanonical,
              name: nameFallback,
              // place,
              image: featuredImage,
              imageProps: {
                objectFit: 'cover',
                borderRadius: '100%',
              },
              boxImageProps: {
                borderRadius: '100%',
              },
            }}
          />

          <Description
            {...{
              description,
              title: t('presentation'),
              icon: 'smile',
              color: mainColor,
            }}
          />

          <SectionExhibitors {...{ exhibitors, t }} />
          <SectionAnimations {...{ animations, t }} />
          <SectionBoardgames
            {...{ boardgames: authorOf, t, title: 'authorOf' }}
          />
          <SectionBoardgames
            {...{ boardgames: illustratorOf, t, title: 'illustratorOf' }}
          />
        </React.Fragment>
      )}
    </Page>
  )
  // TODO: video
}

export const pageQuery = graphql`
  query Guest($id: String!) {
    guest(id: { eq: $id }) {
      nameCanonical
      name {
        fr
        en
        nl
      }
      nameFallback {
        fr
        en
        nl
      }
      relativePath
      description {
        en
        fr
        nl
      }
      featuredImage {
        id
        url
        size
        thumbnails {
          small {
            height
            width
            url
          }
          large {
            url
            width
            height
          }
        }
      }

      exhibitors {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        category
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      animations {
        id
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        days
        start
        end
        categories
        relativePath
        featuredImage {
          id
          url
          size
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              url
              width
              height
            }
          }
        }
      }

      authorOf {
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        featuredImage {
          url
          type
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              height
              width
              url
            }
          }
        }
        duration
        accessibility
        age
        audience
        maxPlayers
        minPlayers
        relativePath
      }

      illustratorOf {
        nameCanonical
        name {
          en
          fr
          nl
        }
        nameFallback {
          en
          fr
          nl
        }
        status
        featuredImage {
          url
          type
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              height
              width
              url
            }
          }
        }
        duration
        accessibility
        age
        audience
        maxPlayers
        minPlayers
        relativePath
      }
    }
  }
`

export default GuestTemplate
